@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.slides {
  margin-top: -180px;
  position: relative;

  @include respond-to(small) {
    margin-top: 0;
  }

  &__backgroundCounter {
    font-family: $font-family-secondary;
    font-weight: 700;
    font-size: 200px;
    color: #fff;
    opacity: 0.1;
    position: absolute;
    left: -170px;
    top: -30px;
  }

  .slick-slider * {
    outline: 0;
  }

  .slick-slide {
    line-height: 0;
  }

  .slick-active {
    .slides__slide__info {
      opacity: 1;
    }
  }

  &_counter {
    font-family: $font-family-secondary;
    color: $color-gray;
    position: absolute;
    font-size: 40px;
    bottom: 90px;
    right: 620px;
    font-weight: bold;
    pointer-events: none;

    @include respond-to(small) {
      right: 50%;
      transform: translate(50%);
      bottom: initial;
      opacity: 0;
    }

    &-current {
      display: inline-block;
      min-width: 48px;
    }

    &-total {
      font-weight: 300;
      font-size: 30px;
    }
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    background: transparent url('../../assets/icons/arrow.png') no-repeat center;
    background-size: 30px;
    position: absolute;
    top: calc(50% - 20px);
    left: -100px;
    border: 0;
    font-size: 0;
    cursor: pointer;
    outline: 0;
    opacity: 1;
    transition: opacity 0.1s;
    z-index: 1000;

    @include respond-to(small) {
      left: 0;
    }

    &.slick-disabled {
      opacity: 0.1;
    }

    &:active {
      margin-left: -1px;
    }

    &.slick-next {
      left: initial;
      right: -100px;
      transform: rotate(180deg);

      @include respond-to(small) {
        right: 0;
      }

      &:active {
        margin-right: -1px;
      }
    }
  }

  &__slide {
    position: relative;
    padding-bottom: 180px;

    @include respond-to(small) {
      padding-bottom: 70px;
    }

    &__info {
      box-shadow: 0 0 100px rgba(0, 0, 0, 0.08);
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      bottom: 100px;
      z-index: 1;
      background-color: #fff;
      width: 570px;
      padding: 40px;
      opacity: 0;
      transition: opacity 0.5s;

      @include respond-to(small) {
        position: static;
        box-shadow: none;
        flex-direction: column;
        width: 100%;
        padding: 100px 0 0 0;
      }

      &_text {
        padding-left: 40px;
        font-weight: 300;
        font-size: 20px;
        line-height: 27px;

        @include respond-to(small) {
          padding-top: 30px;
          text-align: center;
          padding-left: 0;
        }

        b {
          font-weight: 700;
          color: $color-cyan;
        }
      }
    }
  }
}
