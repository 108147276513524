@import '../../assets/scss/mixins';

.header {
  padding: 25px 0;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo_img {
    width: 292px;
  }

  @include respond-to(small) {
    .logo_img {
      width: 233px;
    }

    .button {
      &_text {
        display: none;
      }

      &_icon {
        margin-right: 0;
      }
    }
  }
}
