@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.footer {
  background: #f8f8f8;
  padding: 40px 0;

  &__mail {
    display: flex;
    align-items: center;
    &_icon {
      margin-right: 10px;
    }

    @include respond-to(small) {
      margin-bottom: 30px;
    }
  }

  &_link {
    font-size: 16px;
    line-height: 22px;
    color: $color-gray;

    &-more {
      white-space: nowrap;

      color: $color-cyan;
      text-decoration: none;

      &:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-width: 1px 1px 0 0;
        border-style: solid;
        border-color: $color-cyan;
        transform: rotate(45deg);
        margin-left: 4px;
        position: relative;
      }
    }
  }

  .logo {
    margin-bottom: 20px;
  }

  .logo_img {
    width: 233px;
  }

  &__column {
    &-1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .button {
        display: none;
      }

      @include respond-to(small) {
        padding-bottom: 30px;
        align-items: center;
        order: 1;

        .button {
          display: flex;
          margin-top: 30px;
        }
      }
    }

    &-2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include respond-to(small) {
        align-items: center;
        order: 3;
      }
    }

    &-3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include respond-to(small) {
        order: 2;
        align-items: center;
        padding-bottom: 30px;
      }
    }

    &-4 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      @include respond-to(small) {
        order: 4;
        align-items: center;
        padding-top: 30px;

        .button {
          display: none;
        }
      }
    }
  }
}
