@import '~bootstrap/scss/bootstrap-grid';
@import 'variables';
@import 'mixins';

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/Oswald-Bold.eot');
  src: local('Oswald Bold'), local('Oswald-Bold'), url('../fonts/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Oswald-Bold.woff') format('woff'), url('../fonts/Oswald-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/Oswald-Light.eot');
  src: local('Oswald Light'), local('Oswald-Light'), url('../fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Oswald-Light.woff') format('woff'), url('../fonts/Oswald-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Light.woff') format('woff'), url('../fonts/OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Regular.woff') format('woff'), url('../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-Bold.woff') format('woff'), url('../fonts/OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-SemiBold.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/OpenSans-SemiBold.woff') format('woff'), url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: $font-family-base;
  background: url('../images/background.jpg') no-repeat calc(50% + 480px) 0;
  margin: 0;
  font-size: 16px;

  @include respond-to(small) {
    background: none;
  }
}

a {
  color: $color-cyan;
}

img {
  max-width: 100%;
  height: auto;
}

.gamification {
  overflow: hidden;
}

.text-primary {
  color: $color-primary !important;
}

.text-muted {
  color: $color-gray;
}
