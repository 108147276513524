$smaller: 320px;
$small: 992px;

@mixin respond-to($size) {
  @if $size == small {
    @media only screen and (max-width: $small) {
      @content;
    }
  }

  @if $size == smaller {
    @media only screen and (max-width: $smaller) {
      @content;
    }
  }
}
