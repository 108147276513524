@import '../../assets/scss/variables';

.button {
  font-family: $font-family-base;
  height: 40px;
  background-color: $color-primary;
  border-radius: 4px;
  padding: 0 12px;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  position: relative;
  top: 0;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: $color-primary-light;
    color: #fff;
    text-decoration: none;
  }

  &:active {
    top: 1px;
  }

  &_icon {
    margin-right: 10px;
  }

  &-large {
    padding: 0 30px;
    height: 60px;
    font-size: 20px;
  }
}
