@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.main {
  overflow: hidden;

  @include respond-to(small) {
    background: #053179;
    overflow: initial;
  }

  &__left {
    position: relative;
    color: #fff;
    padding: 80px 100px 0 0;
    display: flex;
    flex-direction: column;

    @include respond-to(small) {
      padding: 0;
    }

    &_notebook {
      background: url('../../assets/images/notebook.png') no-repeat top left;
      width: 1528px;
      height: 1080px;
      display: flex;
      position: absolute;
      top: -55px;
      right: -760px;
      pointer-events: none;
      margin-bottom: -30vw;

      @include respond-to(small) {
        position: static;
        display: flex;
        width: 100vw;
        height: 100vw;
        right: initial;
        background-size: 150%;
        background-position: 50% 0;
        margin-top: 30px;
      }
    }

    &_title {
      font-family: $font-family-secondary;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 50px;
      line-height: 130%;

      &-sub {
        font-family: $font-family-base;
        font-size: 30px;
        text-transform: none;
        @include respond-to(small) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      @include respond-to(small) {
        text-align: center;
        font-size: 40px;
        line-height: 52px;
      }
    }

    &_text {
      font-size: 20px;
      line-height: 28px;
      display: block;
      margin-bottom: 30px;
      @include respond-to(small) {
        margin-bottom: 40px;
      }

      @include respond-to(small) {
        text-align: center;
      }

      &_logo {
        position: relative;
        top: 6px;
      }
    }

    &__content {
      color: #fff;
      position: relative;

      @include respond-to(small) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 50vw;
      background: #053179;
    }

    @include respond-to(small) {
      &:before {
        display: none;
      }
    }

    &__bottom {
      padding-top: 200px;
      padding-bottom: 180px;
      position: relative;
      display: flex;

      @include respond-to(small) {
        padding: 50px 0 0 0;
        justify-content: center;
      }

      &_title {
        font-family: $font-family-secondary;
        font-size: 50px;
        color: $color-primary;
        line-height: 65px;

        @include respond-to(small) {
          text-align: center;
          font-size: 40px;
          line-height: 52px;
          padding: 0 5px;
        }

        &-sub {
          color: #fff;
          @include respond-to(small) {
            color: #000;
          }
        }
      }
    }
  }
}
